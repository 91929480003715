import { styled, keyframes } from '@mui/material/styles';
import IconProgress from '@/svg/icons/progress_btn_icon_20x20.svg';


const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const IconSpinningLoader = styled(IconProgress)`
  animation: ${spin} 2000ms linear infinite;
`;

export default IconSpinningLoader;

import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import {
  styled,
  Button as MuiButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';


const Button = styled(MuiButton)`
  width: 100px;
`;

interface UseConfirmationDialogArgs {
  onConfirm: () => void,
  shouldShowConfirmationDialog?: boolean,
  disableBackdropClick?: boolean,
}

export const useConfirmationDialog = ({
  onConfirm,
  shouldShowConfirmationDialog = true,
  disableBackdropClick = false,
}: UseConfirmationDialogArgs) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleCancel = () => {
    setIsDialogOpen(false);
  };

  const handleConfirm = () => {
    setIsDialogOpen(false);
    onConfirm();
  };

  const handleClose = (_?: unknown, reason?: 'backdropClick' | 'escapeKeyDown') => {
    if (!(reason === 'backdropClick' && disableBackdropClick)) {
      if (shouldShowConfirmationDialog) {
        setIsDialogOpen(true);
      } else {
        handleConfirm();
      }
    }
  };

  return {
    handleClose,
    dialogProps: {
      open: isDialogOpen,
      onCancel: handleCancel,
      onConfirm: handleConfirm,
    },
  };
};


interface ConfirmationDialogProps {
  open: boolean,
  onCancel: () => void,
  onConfirm: () => void,
  content: {
    title?: string,
    mainText: string,
    confirmationText?: string,
    cancelText?: string,
  },
}

const ConfirmationDialog = ({
  open,
  onCancel,
  onConfirm,
  content,
}: ConfirmationDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      sx={{ p: 3 }}
    >
      {content.title && (
        <DialogTitle>
          {content.title}
        </DialogTitle>
      )}
      <DialogContent sx={{ px: 3.5, pt: 0, width: 'auto' }}>
        <DialogContentText sx={{ fontWeight: 600 }}>
          {content.mainText}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ px: 3.5, justifyContent: 'center' }}>
        <Button
          variant="text"
          onClick={onCancel}
          color="primary"
          autoFocus
        >
          {content.cancelText ?? t('common:cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
        >
          {content.confirmationText ?? t('common:ok')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;

import { useCallback, useMemo } from 'react';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import useIsServerSide from './useIsServerSide';


interface PermissionData {
  isLoading: boolean,
  data: string[] | undefined,
}

function usePermissions(): PermissionData {
  const {
    isAuthenticated,
    isLoading: isLoadingAuth,
    getAccessTokenSilently,
  } = useAuth0();
  const isServerSide = useIsServerSide();


  const getPermissions = useCallback(async (): Promise<string[]> => {
    if (!isLoadingAuth && isAuthenticated) {
      const token = await getAccessTokenSilently();
      const decoded = jwtDecode<JwtPayload & { permissions: string[] }>(token);
      return decoded.permissions;
    }
    return [];
  }, [getAccessTokenSilently, isAuthenticated, isLoadingAuth]);

  const {
    isLoading: isLoadingPermissions,
    data = null,
  } = useQuery<string[]>(['permissions', isLoadingAuth], getPermissions);


  return useMemo(() => {
    const ret: PermissionData = {
      isLoading: true,
      data: undefined,
    };
    if (isServerSide) {
      return ret;
    }
    if (data !== null) {
      ret.data = data;
    }
    if (!isLoadingAuth && !isLoadingPermissions) {
      ret.isLoading = false;
    }
    return ret;
  }, [data, isLoadingAuth, isLoadingPermissions, isServerSide]);
}

export default usePermissions;

import React from 'react';
import { useTranslation } from 'next-i18next';
import Button, { ButtonProps } from '@mui/material/Button';


const TrialButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      id="open-request-trial-form-button"
      variant="contained"
      color="secondary"
      fullWidth
      sx={{ mx: 'auto', py: 1.5 }}
      {...props}
    >
      {t('translation:trial.getFreeTrial')}
    </Button>
  );
};

export default React.memo(TrialButton);

import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { styled, Box, useTheme } from '@mui/material';
import { permissions } from '@/constants';
import CategoriesSection from '@/components/AppLayout/Sidebar/MenuContent/CategoriesSection';
import ProtectedByPermissions from '@/components/auth/ProtectedByPermissions';
import { useInstanceContext } from '@/contexts/InstanceContext';
import RequestTrial from '@/components/RequestTrial';
import { useInstanceType } from '@/hooks';
import BotPerformance from './BotPerformance';
import LiveChatSection from './LiveChatSection';
// import BotcopySection from './BotcopySection';
import GorgiasSection from './GorgiasSection';
import TrialButton from './TrialButton';


const Container = styled(Box)`
  padding: 0 var(--theme-spacing-2) var(--theme-spacing-1);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const SpacerForIntercom = styled(Box)`
  height: 65px;
`;

const MenuContent = () => {
  const { instance } = useInstanceContext();
  const instanceType = useInstanceType();
  const theme = useTheme();

  return (
    // @ts-ignore
    <Scrollbars
      autoHide
      universal
      style={{ height: `calc(100% - ${theme.mixins.appLayout.headerHeight})` }}
    >
      <Container>
        <Box>
          {instanceType === 'sandbox' && (
            <Box sx={{ mb: 3 }}>
              <RequestTrial OpenButtonComponent={TrialButton} />
            </Box>
          )}
          <ProtectedByPermissions
            permission={permissions.fullBotEditor}
            noLoader
          >
            <CategoriesSection />
          </ProtectedByPermissions>
          <BotPerformance />
          {/* {instance?.widget.platform === 'botcopy' && <BotcopySection />} */}
          {instance?.widget.platform === 'gorgias' && <GorgiasSection />}
          {instance?.widget.platform === 'livechat' && <LiveChatSection />}
          <SpacerForIntercom />
        </Box>
      </Container>
    </Scrollbars>
  );
};

export default MenuContent;

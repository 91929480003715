import React from 'react';
import { useTranslation } from 'next-i18next';
import IconChat from '@/svg/icons/menu_chats.svg';
import IconArchives from '@/svg/icons/menu_archives.svg';
import LivechatLogo from '@/svg/images/partners/livechat_logo.svg';
import LivechatInfoDialog from '@/components/LivechatInfoDialog';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { CustomIcon, Instance } from '@/types/PerfectBot';
import MenuButton from '../MenuButton';
import ChatIntegration from '../ChatIntegration';


const menuItems: Array<{
  key: keyof Instance['externalLinks'],
  icon: CustomIcon,
  label: string,
}> = [
  {
    key: 'livechat:chats',
    icon: IconChat,
    label: 'translation:menu.chats',
  },
  {
    key: 'livechat:archives',
    icon: IconArchives,
    label: 'translation:menu.archives',
  },
];

const logoHeight = 21;

const LiveChatSection = () => {
  const { t } = useTranslation();
  const [isLivechatModalOpen, setIsLivechatModalOpen] = React.useState<boolean>(false);

  const { instance } = useInstanceContext();
  const { instanceTypeId: instanceType } = instance as Instance;

  return (
    <>
      <ChatIntegration
        menuItems={menuItems}
        headerLogo={(
          <LivechatLogo
            height={logoHeight}
            width={logoHeight * 5}
            style={{ marginLeft: '-5px' }}
          />
        )}
        overrideContent={instanceType === 'sandbox'
          ? menuItems.map((item) => (
            <MenuButton
              key={item.key}
              icon={item.icon}
              label={t(item.label)}
              onClick={() => setIsLivechatModalOpen(true)}
            />
          ))
          : null}
      />
      <LivechatInfoDialog
        open={isLivechatModalOpen}
        onClose={() => setIsLivechatModalOpen(false)}
      />
    </>
  );
};


export default LiveChatSection;

import React from 'react';
import Typography from '@mui/material/Typography';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';
import { IconChevronUp, IconChevronDown } from '@tabler/icons';
import { ButtonBase, lighten } from '@mui/material';


const Container = styled(ButtonBase)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 4px 2px 4px var(--theme-spacing-2);
  min-height: 34px;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: var(--theme-spacing-1);
  
  &:hover {
    background-color: ${({ theme }) => lighten(theme.palette.blue[50], 0.5)};
  }
`;

const Text = styled(Typography)`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-weight: 700;
`;

export interface SectionHeaderProps {
  children: React.ReactNode,
  onClick: () => void,
  open: boolean,
  livechatLogo?: boolean,
}

const SectionHeader = ({
  children,
  onClick,
  open,
  livechatLogo = false,
}: SectionHeaderProps) => (
  <Container
    // @ts-ignore
    component="div"
    role="button"
    onClick={onClick}
    onKeyUp={onClick}
    tabIndex={0}
    sx={livechatLogo ? { py: 0, pl: '7px' } : {}}
  >
    <Text
      variant="h4"
      display="block"
      component="div"
      gutterBottom
    >
      {children}
    </Text>
    <Icon sx={{ transform: 'scale(0.6)' }}>
      {open ? <IconChevronUp /> : <IconChevronDown />}
    </Icon>
  </Container>
);


export default SectionHeader;

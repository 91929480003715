import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Box, styled } from '@mui/material';
import Loader from '@/components/Loader';


const Container = styled(Box)`
  display: grid;
  place-items: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.palette.background.default};
  z-index: ${({ theme }) => theme.zIndex.modal + 1};
`;


const AuthenticationLoader = () => {
  const { isLoading, isAuthenticated } = useAuth0();

  if (isLoading || !isAuthenticated) {
    return (
      <Container>
        <Loader centered size={60} />
      </Container>
    );
  }
  return null;
};


export default AuthenticationLoader;

import React from 'react';
import { useRouter } from 'next/router';
import type { HTTPError } from 'ky';
import { useTranslation } from 'next-i18next';
import range from 'lodash/range';
import { useQuery } from '@tanstack/react-query';
import Collapse from '@mui/material/Collapse';
import type PerfectBot from '@/types/PerfectBot';
import { useCategoriesContext } from '@/contexts/CategoriesContext';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { getCategories } from '@/api/instances';
import { getCategoryIcon } from '@/utils/getCategoryIcon';
import { waitAndGet } from '@/utils/waitAndGet';
import { useInstanceUrlStructure, useOnError } from '@/hooks';
import { urlStructure } from '@/constants';
import MenuLink from '../MenuLink';
import MenuList from '../MenuList';


const CategoriesSection = () => {
  const { t } = useTranslation();
  const onError = useOnError();
  const {
    query: { category: currentCategoryId },
    pathname,
  } = useRouter();
  const {
    categoriesOrder,
    categories,
    setCategories,
  } = useCategoriesContext();
  const instanceUrlStructure = useInstanceUrlStructure();
  const { instanceId } = useInstanceContext();

  const [open, setOpen] = React.useState(true);

  const {
    isLoading,
  } = useQuery<PerfectBot.Category[] | null, HTTPError>(
    ['getCategories', instanceId],
    () => waitAndGet(() => getCategories(instanceId), instanceId),
    {
      staleTime: 5000,
      onError: (error) => onError(error, t('error:errorFetchingCategories'), 'warning'),
      onSuccess: (data) => {
        if (data) {
          setCategories(data);
        }
      },
    },
  );

  return (
    <MenuList
      isListOpen={open}
      toggleOpen={() => setOpen(!open)}
      headerTitle={t('translation:botResponses')}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        {!isLoading && categories
          ? (
            <>
              <MenuLink
                icon={getCategoryIcon('allCategories')}
                label={t('translation:menu:allCategories')}
                active={
                  currentCategoryId === undefined && pathname === urlStructure.intentsInstance()
                }
                href={{ pathname: instanceUrlStructure.intents }}
              />
              {categoriesOrder?.map((categoryId) => (
                <MenuLink
                  key={categoryId}
                  icon={getCategoryIcon(categories[categoryId].icon)}
                  label={categories[categoryId].name}
                  active={currentCategoryId === categoryId}
                  href={{
                    pathname: instanceUrlStructure.intents,
                    query: {
                      category: categoryId,
                    },
                  }}
                />
              ))}
            </>
          )
          : range(0, 9).map((n) => (
            <MenuLink
              key={n}
              skeleton
              icon={getCategoryIcon('')}
              label="loading..."
              active={false}
              href=""
            />
          ))}
      </Collapse>
    </MenuList>
  );
};


export default CategoriesSection;

import React from 'react';
import {
  Icon,
  Skeleton,
  styled,
  ButtonBase,
  Menu,
  List,
  Divider,
  Typography,
  Stack,
  Box,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { getInstanceLabel } from '@/utils/getInstanceLabel';
import { useInstanceType } from '@/hooks';
import type PerfectBot from '@/types/PerfectBot';
import IconSelectArrowDown from '@/svg/icons/select_arrow_down.svg';
import skipPropsPropagation from '@/utils/skipPropsPropagation';
import RequestTrial from '@/components/RequestTrial';
import InstanceSelectionItem from './InstanceSelectionItem';
import Label from './Label';
import TellUsButton from './TellUsButton';


const OpenButton = styled(ButtonBase)`
  padding: var(--theme-spacing-1) var(--theme-spacing-2);
  border-radius: var(--theme-border-radius);
`;

const IconArrow = styled(IconSelectArrowDown, skipPropsPropagation('isOpen'))<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen === true ? 'rotate(-180deg)' : 'none')};
  transition: transform 0.2s ease-in-out;
`;


interface InstanceSelectionLayoutProps {
  isLoading: boolean,
  instance: PerfectBot.Instance | null,
  instanceId: string,
  instances: Array<{ label: string | undefined, value: string, data: PerfectBot.Instance }>,
  anchorEl: HTMLButtonElement | null,
  setAnchorEl: (element: HTMLButtonElement | null) => void,
  changeInstance: (value: string) => void,
}

const InstanceSelectionLayout = ({
  isLoading,
  instance,
  instanceId,
  instances,
  anchorEl,
  setAnchorEl,
  changeInstance,
}: InstanceSelectionLayoutProps) => {
  const { t } = useTranslation();
  const instanceType = useInstanceType();
  const isOpen = Boolean(anchorEl);

  if (isLoading || instance == null) {
    return (
      <Skeleton sx={{ ml: 2 }} variant="rectangular">
        <Label>
          --------------
        </Label>
      </Skeleton>
    );
  }

  if (instances.length === 1 && instanceType !== 'sandbox') {
    return (
      <Label>
        {instances[0].label as string}
      </Label>
    );
  }

  return (
    <>
      <OpenButton
        onClick={(event) => setAnchorEl(event.currentTarget)}
        id="instance-select"
      >
        <Label component="span" noMargin>
          {getInstanceLabel(instance)}
        </Label>
        <Icon sx={{ ml: 1 }}>
          <IconArrow isOpen={isOpen} />
        </Icon>
      </OpenButton>
      <Menu
        MenuListProps={{
          sx: { mx: 2, my: 1.5, py: 1.5 },
          // @ts-ignore
          component: 'div',
        }}
        anchorEl={anchorEl}
        open={isOpen}
        onClose={() => setAnchorEl(null)}
      >
        <List id="instance-select-menu">
          {instances
            .filter(({ value }) => value !== instanceId)
            .map(({ label, value }) => (
              <InstanceSelectionItem
                key={value}
                onClick={() => changeInstance(value)}
              >
                {label}
              </InstanceSelectionItem>
            ))}
          {instanceType === 'sandbox' && (
            <>
              <InstanceSelectionItem
                onClick={() => {
                }}
                disabled
                comingSoon
              >
                {t('translation:instanceSelect.bookstores')}
              </InstanceSelectionItem>
              <InstanceSelectionItem
                onClick={() => {
                }}
                disabled
                comingSoon
              >
                {t('translation:instanceSelect.electronics')}
              </InstanceSelectionItem>
              <InstanceSelectionItem
                onClick={() => {
                }}
                disabled
                comingSoon
              >
                {t('translation:instanceSelect.sports')}
              </InstanceSelectionItem>
            </>
          )}
        </List>
        {instanceType === 'sandbox' && (
          <Box>
            <Divider sx={{ mx: 3 }} />
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ px: 3, py: 1 }}
            >
              <Typography variant="body1" sx={(theme) => ({ color: theme.palette.grey[900] })}>
                {t('translation:instanceSelect.wantNewCategory?')}
              </Typography>
              <RequestTrial OpenButtonComponent={TellUsButton} />
            </Stack>
          </Box>
        )}
      </Menu>
    </>
  );
};


export default InstanceSelectionLayout;

import React from 'react';
import { styled } from '@mui/material/styles';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import IconClose from '@/svg/icons/close_12.svg';


const CloseButton = styled(IconButton)`
  position: absolute;
  top: var(--theme-spacing-1);
  right: var(--theme-spacing-1);
  font-size: 24px;
`;

interface DialogWithXButtonProps extends DialogProps {
  // onClose?: {
  //   bivarianceHack(event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'xButton'): void,
  // }['bivarianceHack'],
  onClose?: (event: {}, reason: 'backdropClick' | 'escapeKeyDown' | 'xButton') => void,
}

const DialogWithXButton = ({
  onClose,
  children,
  ...otherProps
}: DialogWithXButtonProps) => (
  <Dialog
    onClose={onClose}
    {...otherProps}
  >
    {onClose && (
      <CloseButton
        onClick={() => onClose({}, 'xButton')}
        aria-label="Close"
      >
        <IconClose />
      </CloseButton>
    )}
    {children}
  </Dialog>
);


export default DialogWithXButton;

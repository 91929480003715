import React from 'react';
import { Form, useFormikContext } from 'formik';
import { useTranslation, Trans } from 'next-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import {
  styled,
  DialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle,
  Stack,
  TextField,
  Typography,
  Box,
  Link,
  alpha,
} from '@mui/material';
import DialogWithXButton from '@/components/DialogWithXButton';
import {
  firstContactMail,
  privacyPolicy,
  termsOfService,
} from '@/constants';
import type { FormValues } from './types';


const ButtonLink = styled(Typography)`
  background: none;
  border: none;
  color: ${({ theme }) => theme.palette.text.primary};
  cursor: pointer;
  padding: 0;
  text-decoration: underline;
  
  // this repetition gives hover effect
  text-decoration-color: currentcolor;
  text-decoration-color: ${({ theme }) => alpha(theme.palette.primary.main, 0.4)};
  
  :hover {
    text-decoration-color: inherit;
  }
`;

const DialogContent = styled(MuiDialogContent)`
  width: 400px;
`;

interface RequestTrialProps {
  isOpen: boolean,
  activeStep: number,
  isMutationInProgress: boolean,
  handleClose: () => void,
}

const RequestTrialDialog = ({
  isOpen,
  activeStep,
  isMutationInProgress,
  handleClose,
}: RequestTrialProps) => {
  const { t } = useTranslation();
  const {
    values,
    touched,
    errors,
    handleChange,
    isSubmitting,
  } = useFormikContext<FormValues>();


  return (
    <DialogWithXButton
      onClose={handleClose}
      open={isOpen}
      PaperProps={{ sx: { height: '385px' } }}
    >
      {activeStep === 0 && (
        <Form id="request-trial-form">
          <DialogTitle sx={{ textAlign: 'center' }}>
            <Typography component="div" variant="h1">
              {t('translation:requestTrial.title')}
            </Typography>
            <Typography component="div" variant="subtitle1">
              <Trans i18nKey="translation:requestTrial.subtitle">
                30-day
                <Box component="span" sx={({ palette }) => ({ color: palette.secondary.main })}>
                  free
                </Box>
                trial. No credit card needed.
              </Trans>
            </Typography>
          </DialogTitle>
          <DialogContent sx={{ pb: 2 }}>
            <Stack
              spacing="3px"
              sx={{ mt: 1 }}
            >
              <TextField
                fullWidth
                id="email"
                name="email"
                label={t('translation:requestTrial.email')}
                value={values.email}
                onChange={handleChange}
                error={touched.email && Boolean(errors.email)}
                helperText={(touched.email && errors.email) ? t(errors.email) : ' '}
              />
              <TextField
                fullWidth
                id="website"
                name="website"
                label={t('translation:requestTrial.website')}
                value={values.website}
                onChange={handleChange}
                error={touched.website && Boolean(errors.website)}
                helperText={(touched.website && errors.website) ? t(errors.website) : ' '}
              />
            </Stack>
          </DialogContent>
          <DialogActions
            sx={{
              py: 0,
              flexDirection: 'column',
            }}
          >
            <LoadingButton
              autoFocus
              fullWidth
              loading={isMutationInProgress}
              disabled={isSubmitting}
              color="secondary"
              variant="contained"
              size="large"
              type="submit"
            >
              {t('translation:requestTrial.submit')}
            </LoadingButton>
          </DialogActions>
          <DialogContent sx={{ pb: 0 }}>
            <Typography sx={{ textAlign: 'center', fontSize: '0.75rem' }}>
              <Trans i18nKey="translation:requestTrial.footer">
                By clicking “Get 1-month free trial,” you agree to the
                <Link
                  href={privacyPolicy}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Privacy Policy
                </Link>
                and
                <Link
                  href={termsOfService}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Terms of Service
                </Link>
                .
              </Trans>
            </Typography>
          </DialogContent>
        </Form>
      )}
      {activeStep === 1 && (
        <DialogContent
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <Box>
            <Typography
              component="div"
              variant="h1"
              sx={{ mb: 4.5, textAlign: 'center' }}
            >
              <Trans i18nKey="translation:requestTrial.thankYouMessage">
                <Box sx={({ palette }) => ({ color: palette.secondary.main })}>
                  Thank you.
                </Box>
                We’ll get back to you shortly.
              </Trans>
            </Typography>
            <Typography variant="subtitle1" sx={{ textAlign: 'center' }}>
              <Trans
                i18nKey="translation:requestTrial.thankYouMessageContactUs"
                values={{ email: firstContactMail }}
              >
                We’re happy to answer your questions via
                <ButtonLink
                  // @ts-ignore
                  component="button"
                  variant="subtitle1"
                  onClick={() => {
                    handleClose();
                    window.Intercom('show');
                  }}
                >
                  Intercom Chat
                </ButtonLink>
                or
                <Link
                  href={`mailto:${firstContactMail}`}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  <span>
                    email
                  </span>
                </Link>
              </Trans>
            </Typography>
          </Box>
        </DialogContent>
      )}
    </DialogWithXButton>
  );
};


export default RequestTrialDialog;

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiToolbar from '@mui/material/Toolbar';
import TestLiveBot from '@/components/TestLiveBot';
import ProfileSection from './ProfileSection';
import InstanceSelection from './InstanceSelection';


const Toolbar = styled(MuiToolbar)`
  grid-area: header;
  padding-left: calc(var(--theme-spacing-1) + 6px) !important;
`;


const Header = () => (
  <Toolbar>
    {/* Bot Context Selection */}
    <InstanceSelection />
    <Box sx={{ flexGrow: 1 }} />
    <Box sx={{ flexGrow: 1 }} />

    {/* notification & profile */}
    <TestLiveBot />
    <ProfileSection />
  </Toolbar>
);

export default Header;

import type { SlideProps } from '@mui/material';
import { Box, Container, styled } from '@mui/material';
import type { Theme } from '@mui/material/styles/createTheme';
import skipPropsPropagation from '@/utils/skipPropsPropagation';


const getMainWidthTransition = (
  { transitions: { duration, easing } }: Theme,
  asObject: boolean = false,
): Partial<SlideProps> | string => {
  const params = {
    timeout: duration.standard,
    easing: easing.easeInOut,
  };
  return asObject
    ? params
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    : `width ${params.timeout}ms ${params.easing}`;
};


export const TopContainer = styled(Box)`
  display: grid;
  grid-template-columns: ${({ theme }) => theme.mixins.appLayout.sidebarWidth} auto;
  grid-template-rows: ${({ theme }) => theme.mixins.appLayout.headerHeight} auto;
  grid-template-areas: 
    "sidebar header"
    "sidebar main";
  min-height: 100vh;
`;

export const Main = styled(
  Container,
  skipPropsPropagation('isSideContentVisible'),
)<{ isSideContentVisible: boolean }>`
  --heders-bottom-border: 2px solid ${({ theme }) => (theme.palette.blue[75])};
  
  --main-padding: 20px;
  --header-height: ${({ theme }) => theme.mixins.appLayout.headerHeight};
  
  --main-element-content-height: calc(100vh - (var(--header-height) + (2 * var(--main-padding))));
  
  
  // animation
  --sidebar-width: ${({ theme }) => (theme.mixins.appLayout.sidebarWidth)};
  --avaleble-width-for-content: calc(var(--avaleble-width) -  var(--sidebar-width));

  --main-content-width: ${({ isSideContentVisible }) => (isSideContentVisible
    ? 'calc(0.25 * var(--avaleble-width-for-content))'
    : 'var(--avaleble-width-for-content)')};

  // side content
  --side-content-width: calc(0.75 * var(--avaleble-width-for-content));
  
  
  grid-area: main;
  width: 100%;
  padding: 0 !important;
  max-height: calc(100vh - var(--header-height));
  background: ${({ theme }) => theme.palette.background.background};
  overflow: hidden;
`;

export const MainInnerBox = styled(Box)`
  display: flex;
  flex-direction: row;
  width: fit-content;
`;

export const MainContentWrapper = styled(Box)`
  width: var(--main-content-width);
  padding: var(--main-padding);
  transition: ${({ theme }) => getMainWidthTransition(theme) as string};
`;

export const SideContentWrapper = styled(
  Box,
  skipPropsPropagation('isSideContentVisible'),
)<{ isSideContentVisible: boolean }>`
  width: var(--side-content-width);
  transition: ${({ theme }) => getMainWidthTransition(theme) as string};
  padding: var(--main-padding);
  padding-left: 0;
  visibility: ${({ isSideContentVisible }) => (isSideContentVisible ? 'visible' : 'hidden')};
`;


import React, { useEffect, useMemo } from 'react';
import log from 'loglevel';
import type { HTTPError } from 'ky';
import { useAuth0 } from '@auth0/auth0-react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';
import { getInstances } from '@/api/users';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { getInstanceLabel } from '@/utils/getInstanceLabel';
import { useOnError } from '@/hooks';
import type PerfectBot from '@/types/PerfectBot';
import InstanceSelectionLayout from './InstanceSelectionLayout';


const InstanceSelection = () => {
  const { t } = useTranslation();
  const onError = useOnError();
  const { user } = useAuth0();
  const { instance, instanceId, changeInstance } = useInstanceContext();

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const {
    data,
    isLoading,
  } = useQuery<PerfectBot.Instance[] | null, HTTPError>(
    ['instances', user?.sub],
    () => getInstances(user?.sub),
    {
      staleTime: 5000,
      onError: (error) => onError(error, t('error:errorFetchingInstances'), 'warning'),
    },
  );

  const instances = useMemo(() => (
    (data ?? [])
      .map((currentInstance) => {
        const { id: currentInstanceId } = currentInstance;

        return ({
          label: getInstanceLabel(currentInstance),
          value: currentInstanceId,
          data: currentInstance,
        });
      })
      .sort((a, b) => a.label.localeCompare(b.label))
  ), [data]);


  const handleChangeInstance = (value: string) => {
    const newInstance = instances.find(
      (currentInstance) => (currentInstance.value === value)
    );
    if (newInstance) {
      changeInstance(newInstance.data);
    }

    setAnchorEl(null);
  };

  useEffect(() => {
    if (instanceId !== '' && data && data.length > 0) {
      const allIds = (data).map((currentInstance) => currentInstance.id);
      if (!allIds.includes(instanceId)) {
        log.error('Current instance is not on the list of available instances');
      }
    }
  }, [data, instanceId, instances]);


  return (
    <InstanceSelectionLayout
      isLoading={isLoading}
      instance={instance}
      instanceId={instanceId}
      instances={instances}
      anchorEl={anchorEl}
      setAnchorEl={setAnchorEl}
      changeInstance={handleChangeInstance}
    />
  );
};


export default InstanceSelection;

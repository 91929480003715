import React from 'react';
import { useTranslation } from 'next-i18next';
import log from 'loglevel';
import { styled } from '@mui/material/styles';
import {
  Box,
  Divider as MuiDivider,
  List as MuiList,
  Typography,
  listItemButtonClasses,
  listItemIconClasses,
  MenuList,
} from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import {
  urlStructure,
  permissions,
  stripePanelUrl,
  PerfectBotCookies,
} from '@/constants';
import { useUserData, useInstanceType } from '@/hooks';
import { eraseCookie } from '@/utils/cookies';
import ProtectedByPermissions from '@/components/auth/ProtectedByPermissions';
// import IconUserAccountSettings from '@/svg/icons/user_account_settings.svg';
import IconUserBilling from '@/svg/icons/user_billing.svg';
import IconUserHelpSupport from '@/svg/icons/user_help_support.svg';
import IconUserLogout from '@/svg/icons/user_logout.svg';
import { MenuItem } from './MenuItem';
import UserInfo from './UserInfo';


const List = styled(MuiList)`
  width: 100%;
  max-width: 350px;
  min-width: 300px;
  background-color: ${({ theme }) => theme.palette.background.paper};
  border-radius: 10px;
  padding: 0;

  & .${listItemButtonClasses.root} {
    margin-top: 4px;
  }

,
& . ${listItemIconClasses.root} {
  color: ${({ theme }) => theme.palette.primary.light};
},
`;

const Divider = styled(MuiDivider)`
  margin: var(--theme-spacing-1) var(--theme-spacing-2);
`;

interface MenuContentProps {
  handleClose: () => void,
}

const MenuContent = ({ handleClose }: MenuContentProps) => {
  const { t } = useTranslation();

  const { authData, userData } = useUserData();

  const instanceType = useInstanceType();
  const { logout } = useAuth0();

  const handleLogout = () => {
    try {
      eraseCookie(PerfectBotCookies.GORGIAS_APP_ID_OVERRIDE);
      logout({
        returnTo: window.location.origin + urlStructure.login,
      });
    } catch (err) {
      log.error(err);
    }
  };

  return (
    <MenuList
      component="div"
      id="profile-menu"
    >
      <UserInfo
        authData={authData}
        userData={userData}
      />
      <Box
        sx={{
          height: '100%',
          maxHeight: 'calc(100vh - 250px)',
          overflowX: 'hidden',
        }}
      >
        <Box sx={{ p: 3, pt: 1 }}>
          <List
            // @ts-ignore
            component="nav"
          >
            <Divider />
            {/* <MenuItem */}
            {/*  selected={selectedIndex === 0} */}
            {/*  onClick={(event) => { */}
            {/*    handleListItemClick(event, 0, '#'); */}
            {/*  }} */}
            {/*  icon={<IconUserAccountSettings />} */}
            {/*  label={( */}
            {/*    <Typography variant="body2"> */}
            {/*      {t('translation:accountSettings')} */}
            {/*    </Typography> */}
            {/*  )} */}
            {/* /> */}
            {instanceType === 'paid' && (
              <ProtectedByPermissions permission={permissions.fullAccountBilling} noLoader>
                <MenuItem
                  onClick={() => {
                    const url = new URL(stripePanelUrl);
                    if (authData?.email) {
                      url.searchParams.append('prefilled_email', authData.email);
                    }
                    window.open(
                      url,
                      '_blank',
                      'noreferrer,noopener'
                    );
                    handleClose();
                  }}
                  icon={<IconUserBilling />}
                  label={(
                    <Typography variant="body2">
                      {t('translation:billing')}
                    </Typography>
                  )}
                />
              </ProtectedByPermissions>
            )}
            <MenuItem
              onClick={() => {
                window.Intercom('show');
                handleClose();
              }}
              icon={<IconUserHelpSupport />}
              label={(
                <Typography variant="body2">
                  {t('translation:help')}
                </Typography>
              )}
            />
            <Divider />
            <MenuItem
              onClick={handleLogout}
              icon={<IconUserLogout />}
              label={(
                <Typography variant="body2">
                  {t('common:logout')}
                </Typography>
              )}
            />
          </List>
        </Box>
      </Box>
    </MenuList>
  );
};


export default MenuContent;

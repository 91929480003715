import React from 'react';
import { useTranslation } from 'next-i18next';
import Collapse from '@mui/material/Collapse';
import IconMenuAutomationRate from '@/svg/icons/menu_automation_rate.svg';
import IconMenuTopResponses from '@/svg/icons/menu_top_responses.svg';
import IconMenuAccuracy from '@/svg/icons/menu_accuracy.svg';
import { useInstanceContext } from '@/contexts/InstanceContext';
import type { CustomIcon, Instance } from '@/types/PerfectBot';
import MenuLink from '../MenuLink';
import MenuList from '../MenuList';


const menuItems: Array<{
  icon: CustomIcon,
  label: string,
  key: keyof Instance['externalLinks'],
}> = [
  {
    icon: IconMenuAutomationRate as CustomIcon,
    label: 'translation:menu.topResponses',
    key: 'bot-performance:top-responses',
  },
  {
    icon: IconMenuTopResponses as CustomIcon,
    label: 'translation:menu.automationRate',
    key: 'bot-performance:automation-rate',
  },
  {
    icon: IconMenuAccuracy as CustomIcon,
    label: 'translation:menu.accuracy',
    key: 'bot-performance:accuracy',
  },
];

const BotPerformance = () => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);
  const { instance } = useInstanceContext();

  if (!instance) { return null }

  const { externalLinks } = instance;

  if (!externalLinks || menuItems.every((item) => externalLinks[item.key] == null)) {
    return null;
  }

  return (
    <MenuList
      isListOpen={open}
      toggleOpen={() => setOpen(!open)}
      headerTitle={t('translation:menu.botPerformance')}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        {menuItems.map((item) => (
          <MenuLink
            key={item.key}
            target="_blank"
            rel="noopener noreferrer"
            href={externalLinks[item.key] ?? ''}
            label={t(item.label)}
            icon={item.icon}
            isExternal
          />
        ))}
      </Collapse>
    </MenuList>
  );
};


export default BotPerformance;

import { useMemo } from 'react';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { urlStructure } from '@/constants';
import { homeAddress } from '@/constants/urlStructure';


function useInstanceUrlStructure(instanceId?: string) {
  const { instanceId: contextInstanceId } = useInstanceContext();

  const id = instanceId ?? contextInstanceId;
  return useMemo(() => ({
    homeAddress: homeAddress(id),
    app: urlStructure.appInstance(id),
    intents: urlStructure.intentsInstance(id),
    error: urlStructure.error, // error added to simplify error flow
  }), [id]);
}

export default useInstanceUrlStructure;

import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  ButtonBase,
  styled,
  Popover,
  Skeleton,
} from '@mui/material';
import { useUserData } from '@/hooks';
import UserAvatar from '@/components/UserAvatar';
import MenuContent from './MenuContent';


const ProfileMenuOpenButton = styled(ButtonBase)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  padding: 0 ${({ theme }) => theme.spacing(1.5)};
`;

const ProfileSection = () => {
  /**
   * anchorRef is used on different components and specifying one type leads
   * to other components throwing an error
   * */
  const { t } = useTranslation();
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const { authData, userData } = useUserData();
  const [open, setOpen] = React.useState(false);

  const handleToggle = () => {
    setOpen((prevStateOpen) => !prevStateOpen);
  };

  const handleClose = (event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as Node)) {
      return;
    }
    setOpen(false);
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    // this comparison to exactly true is on purposed
    if (prevOpen.current === true && open === false) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <>
      <ProfileMenuOpenButton
        id="profile-menu-open-button"
        component="div"
        role="button"
        // @ts-ignore
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        {userData?.firstName
          ? t('translation:hiUserName', { userName: userData?.firstName })
          : <Skeleton width="70px" />}
        <UserAvatar
          userId={authData?.sub}
          sx={{
            margin: '8px 0 8px 8px !important',
            cursor: 'pointer',
          }}
          aria-controls={open ? 'menu-list-grow' : undefined}
          aria-haspopup="true"
          color="inherit"
        />
      </ProfileMenuOpenButton>
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuContent
          handleClose={() => setOpen(false)}
        />
      </Popover>
    </>
  );
};

export default ProfileSection;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { useBotContext } from '@/contexts/BotContext';
import OpenButton from './OpenButton';


const TestLiveBot = () => {
  const { t } = useTranslation();
  const {
    toggleChatWindow,
    isBotAvailable,
    botStatus,
  } = useBotContext();

  const [isButtonResponsive, setIsButtonResponsive] = useState<boolean>(true);

  useEffect(() => {
    if (!isButtonResponsive) {
      setTimeout(() => {
        setIsButtonResponsive(true);
      }, 500);
    }
  }, [isButtonResponsive]);


  const handleClick = () => {
    if (isButtonResponsive) {
      toggleChatWindow();
      setIsButtonResponsive(false);
    }
  };

  const disabled = !isBotAvailable && botStatus !== 'loading';
  return (
    <Tooltip
      // @ts-ignore
      title={t('translation:testingNotYetAvailable')}
      PopperProps={{ style: !disabled ? { display: 'none' } : {} }}
    >
      {/* Box bellow is needed for tooltip, to work with disabled button */}
      <Box sx={{ mr: 2 }}>
        <OpenButton
          handleClick={handleClick}
          botStatus={botStatus}
          disabled={disabled}
        />
      </Box>
    </Tooltip>
  );
};


export default TestLiveBot;

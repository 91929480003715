import React from 'react';
import { useRouter } from 'next/router';
import { urlStructure } from '@/constants';
import { usePermissions } from '@/hooks';
import { Loader } from '@/components/Loader';


interface ProtectedByPermissionsProps {
  permission: string,
  children: React.ReactNode,
  loader?: React.ReactNode,
  redirect?: boolean,
  noLoader?: boolean,
}

const ProtectedByPermissions = ({
  permission,
  children,
  loader,
  redirect = false,
  noLoader = false,
}: ProtectedByPermissionsProps) => {
  const router = useRouter();

  const {
    isLoading,
    data = [],
  } = usePermissions();

  const hasPermissions = data.includes(permission);

  if (!isLoading && !hasPermissions && redirect) {
    void router.push(urlStructure.app);
  }

  if (isLoading && !noLoader) {
    return (
      // eslint-disable-next-line react/jsx-no-useless-fragment
      <>
        {loader ?? (<Loader />)}
      </>
    );
  }

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {hasPermissions && children}
    </>
  );
};


export default ProtectedByPermissions;

import React from 'react';
import dynamic from 'next/dynamic';
import { styled } from '@mui/material/styles';
import { Box, Paper } from '@mui/material';
import MenuContent from './MenuContent';


const LogoSection = dynamic(() => import('./LogoSection'), {
  ssr: false,
});

const DrawerPaper = styled(Paper)`
  grid-area: sidebar;
  z-index: ${({ theme }) => theme.zIndex.drawer};
  color: ${({ theme }) => theme.palette.text.primary};
  border-right: none;
  box-shadow: 3px 0 4px rgba(0, 41, 103, 0.19);
  max-height: 100vh;
  overflow-y: hidden;
`;

const LogoContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-right: 8px;
  height: ${({ theme }) => theme.mixins.appLayout.headerHeight};
`;

const Sidebar = () => (
  <DrawerPaper
    // @ts-ignore
    component="nav"
    square
  >
    <LogoContainer component="span">
      <LogoSection />
    </LogoContainer>
    <MenuContent />
  </DrawerPaper>
);


export default Sidebar;

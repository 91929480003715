import React from 'react';
import { useTranslation } from 'next-i18next';
import Collapse from '@mui/material/Collapse';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { CustomIcon, Instance } from '@/types/PerfectBot';
import MenuList from './MenuList';
import MenuLink from './MenuLink';


interface ChatIntegrationProps {
  headerLogo: React.ReactNode,
  menuItems: Array<{
    key: keyof Instance['externalLinks'],
    icon: CustomIcon,
    label: string,
  }>,
  overrideContent?: React.ReactNode | null,
}


const ChatIntegration = ({
  headerLogo,
  menuItems,
  overrideContent,
}: ChatIntegrationProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(true);

  const { instance } = useInstanceContext();
  const { externalLinks } = instance as Instance;

  const existingMenuItems = menuItems
    .filter((menuItem) => ((externalLinks ?? {})[menuItem.key] != null));

  if (!overrideContent && existingMenuItems.length === 0) {
    return null;
  }

  return (
    <MenuList
      isListOpen={open}
      toggleOpen={() => setOpen(!open)}
      headerTitle={headerLogo}
    >
      <Collapse in={open} timeout="auto" unmountOnExit>
        {overrideContent ?? existingMenuItems.map((item) => (
          <MenuLink
            key={item.key}
            icon={item.icon}
            label={t(item.label)}
            href={externalLinks[item.key] as string}
            target="_blank"
            rel="noopener noreferrer"
          />
        ))}
      </Collapse>
    </MenuList>
  );
};


export default ChatIntegration;

import React from 'react';
import { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';
import withProtectionByPermission from '@/components/auth/withProtectionByPermission';
import MenuButton, { MenuButtonProps } from './MenuButton';


type SanitizedLinkProps = Pick<LinkProps, | 'as' | 'href'>;
type SanitizedAnchorProps = Partial<Pick<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'target' | 'rel'>>;

export interface MenuLinkProps extends MenuButtonProps, SanitizedLinkProps, SanitizedAnchorProps {
  permission?: string,
}

const MenuLink = ({
  permission,
  href,
  active,
  ...otherProps
}: MenuLinkProps) => {
  const { asPath } = useRouter();
  const isExternal = typeof href === 'string' && (href.indexOf('http') === 0 || href.indexOf('mailto:') === 0);


  if (isExternal) {
    return (
      <MenuButton
        // @ts-ignore
        component="a"
        href={href}
        active={active}
        {...otherProps}
      />
    );
  }

  const isActive = asPath === href || asPath === otherProps.as || active;

  return (
    <Link
      legacyBehavior
      href={href}
    >
      <MenuButton
        // @ts-ignore
        component="a"
        active={isActive}
        {...otherProps}
      />
    </Link>
  );
};

export default withProtectionByPermission(MenuLink);

import React from 'react';
import ProtectedByPermissions from '@/components/auth/ProtectedByPermissions';


interface WithProtectionByPermissionsProps {
  permission?: string,
}

const withProtectionByPermission = <P extends object>(
  OriginalComponent: React.ComponentType<P>
): React.FC<P & WithProtectionByPermissionsProps> => (
    ({ permission, ...otherProps }: WithProtectionByPermissionsProps) => {
      if (!permission) {
        return <OriginalComponent {...otherProps as P} />;
      }

      return (
        <ProtectedByPermissions permission={permission} noLoader>
          <OriginalComponent {...otherProps as P} />
        </ProtectedByPermissions>
      );
    }
  );


export default withProtectionByPermission;


import React from 'react';
import { useTranslation } from 'next-i18next';
import ButtonWithGradient from '@/components/buttons/ButtonWithGradient';


interface OpenButtonProps {
  handleClick: () => void,
  botStatus: 'loading' | 'open' | 'closed',
  disabled: boolean,
}

const OpenButton = ({
  handleClick,
  botStatus,
  disabled,
}: OpenButtonProps) => {
  const { t } = useTranslation();

  let status: 'loading' | 'inProgress' | 'ready';
  let content = t('translation:testLive');
  switch (botStatus) {
    case 'loading':
      status = 'loading';
      break;
    case 'open':
      status = 'inProgress';
      content = t('translation:closeChatbot');
      break;
    case 'closed':
    default:
      status = 'ready';
      break;
  }

  return (
    <ButtonWithGradient
      status={status}
      onClick={handleClick}
      disabled={disabled}
    >
      {content}
    </ButtonWithGradient>
  );
};

export default OpenButton;

import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  styled,
  MenuItem,
  Chip as MuiChip,
  chipClasses,
  Box,
} from '@mui/material';
import Label from './Label';


const Chip = styled(MuiChip)`
  margin-left: auto;
  text-transform: uppercase;
  border-radius: 3px;
  font-size: 0.625rem;
  height: 18px;
  background-color: ${({ theme }) => theme.palette.common.darkPurple};
  
  .${chipClasses.label} {
    padding: 0 4px;
  }
`;

export interface InstanceSelectionItemProps {
  onClick: () => void,
  children: string | undefined,
  disabled?: boolean,
  comingSoon?: boolean,
}

const InstanceSelectionItem = ({
  onClick,
  children,
  disabled,
  comingSoon,
}: InstanceSelectionItemProps) => {
  const { t } = useTranslation();
  return (
    <MenuItem
      onClick={disabled ? () => {
      } : onClick}
      disabled={disabled}
      sx={{
        borderRadius: 'var(--theme-border-radius)',
        '&.Mui-disabled': { opacity: 1 },
      }}
    >
      <Label
        component="span"
        disabled={disabled}
        noMargin
        sx={{ fontWeight: 600 }}
      >
        {children}
      </Label>
      {comingSoon && (
        <>
          <Box sx={{ ml: 3 }} />
          <Chip
            color="primary"
            size="small"
            label={t('translation:instanceSelect.comingSoon')}
          />
        </>
      )}
    </MenuItem>
  );
};


export default InstanceSelectionItem;

/* eslint-disable react/prop-types */
import React from 'react';
import { styled, Avatar, AvatarProps } from '@mui/material';
import { getAvatarUrl } from '@/utils/avatar';


const DefaultImage = styled('img')`
  max-width: 100%;
  max-height: 100%
`;

export interface UserAvaProps extends AvatarProps {
  userId: string | undefined,
}

const UserAvatar = React.forwardRef<HTMLDivElement, UserAvaProps>(
  (props, ref) => {
    const {
      userId,
      ...otherProps
    } = props;
    return (
      <Avatar
        ref={ref}
        src={userId ? getAvatarUrl(userId) : undefined}
        {...otherProps}
      >
        <DefaultImage
          src="/assets/img/fallback-avatar.png"
          alt="avatar"
        />
      </Avatar>
    );
  }
);


export default UserAvatar;

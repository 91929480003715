import React from 'react';
import { styled } from '@mui/material/styles';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';


const StyledListItemButton = styled(ListItemButton)`
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
`;

interface MenuItemProps {
  label: React.ReactNode,
  icon: React.ReactNode,
  onClick: (event: React.MouseEvent<HTMLDivElement>) => void,
}

export const MenuItem = ({
  onClick, icon, label,
}: MenuItemProps) => (
  <StyledListItemButton
    onClick={onClick}
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText
      primary={label}
    />
  </StyledListItemButton>
);

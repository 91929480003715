import React from 'react';
import Sidebar from './Sidebar';
import Header from './Header';
import AuthenticationLoader from './AuthenticationLoader';
import {
  Main,
  MainContentWrapper,
  MainInnerBox,
  SideContentWrapper,
  TopContainer,
} from './styledComponents';


export interface AppLayoutLayoutProps {
  children: React.ReactNode,
  sideContent?: undefined,
  closeSideContent?: undefined,
  showSideContent?: undefined,
}


export interface AppLayoutWithSideContentProps {
  children: React.ReactNode,
  sideContent: React.ReactNode,
  showSideContent: boolean,
}


const AppLayout = ({
  children,
  sideContent,
  showSideContent = false,
}: AppLayoutLayoutProps | AppLayoutWithSideContentProps) => (
  <TopContainer>
    <AuthenticationLoader />
    <Sidebar />
    <Header />
    <Main
      // @ts-ignore
      component="main"
      maxWidth={false}
      isSideContentVisible={showSideContent}
    >
      <MainInnerBox>
        <MainContentWrapper>
          {children}
        </MainContentWrapper>
        <SideContentWrapper isSideContentVisible={showSideContent}>
          {sideContent}
        </SideContentWrapper>
      </MainInnerBox>
    </Main>
  </TopContainer>
);

export default AppLayout;

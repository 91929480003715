import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { useTranslation } from 'next-i18next';


const TellUsButton = (props: ButtonProps) => {
  const { t } = useTranslation();
  return (
    <Button
      variant="outlined"
      size="small"
      sx={{ width: '110px' }}
      {...props}
    >
      {t('translation:instanceSelect.tellUs')}
    </Button>
  );
};


export default React.memo(TellUsButton);

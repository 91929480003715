/* eslint-disable react/require-default-props */
import React from 'react';
import clsx from 'clsx';
import Skeleton from '@mui/material/Skeleton';
import MuiListItemButton, { ListItemButtonProps } from '@mui/material/ListItemButton';
import MuiListItemIcon from '@mui/material/ListItemIcon';
import MuiListItemText from '@mui/material/ListItemText';
import { styled } from '@mui/material';
import IconMenuNewWindow from '@/svg/icons/menu_new_window.svg';
import type { CustomIcon } from '@/types/PerfectBot';
import skipPropsPropagation from '@/utils/skipPropsPropagation';


const ListItemButton = styled(MuiListItemButton)`
  align-items: flex-start;
  border-radius: ${({ theme }) => theme.shape.borderRadius}px;
  margin-bottom: var(--theme-spacing-1);
  background-color: inherit;
  padding-right: 2px;
`;

const ListItemText = styled(MuiListItemText, skipPropsPropagation('active'))<{ active?: boolean }>`
  span.MuiListItemText-primary {
    color: inherit;
    font-weight: ${({ active }) => (active ? 700 : 600)};
  }
`;

const ListItemIcon = styled(MuiListItemIcon)`
  margin: auto var(--theme-spacing-1) auto 0;
`;

const StyledIconMenuNewWindow = styled(IconMenuNewWindow)`
  color: ${({ theme }) => (
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    theme.palette.blue[200]
  )};
`;

export interface MenuButtonProps extends ListItemButtonProps {
  skeleton?: boolean,
  label: string,
  icon: CustomIcon,
  isExternal?: boolean,
  active?: boolean,
}


const MenuButton = React.forwardRef<HTMLDivElement, MenuButtonProps>((
  {
    /* eslint-disable react/prop-types */
    skeleton = false,
    label,
    icon,
    isExternal = false,
    active = false,
    className = '',
    ...otherProps
    /* eslint-enable react/prop-types */
  }: MenuButtonProps,
  ref
) => {
  const Icon = icon;

  return (
    <ListItemButton
      ref={ref}
      className={clsx(className, { 'Mui-selected': active })}
      {...otherProps}
    >
      <ListItemIcon>
        {skeleton
          ? (
            <Skeleton variant="circular" sx={{ height: '1.3rem' }}>
              <Icon
                style={{ width: '1.3rem', height: '1.3rem' }}
              />
            </Skeleton>
          )
          : (
            <Icon
              style={{ width: '1.3rem', height: '1.3rem' }}
            />
          )}
      </ListItemIcon>
      {skeleton
        ? (
          <Skeleton variant="text">
            <ListItemText primary={label} />
          </Skeleton>
        )
        : (
          <ListItemText
            primary={label}
            active={active}
          />
        )}
      {(!skeleton && isExternal) && (
        <StyledIconMenuNewWindow height={24} width={24} />
      )}
    </ListItemButton>
  );
});


export default React.memo(MenuButton);

import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { useTranslation } from 'next-i18next';
import { useUnsavedChangesManager } from '@/hooks';
import ConfirmationDialog, { useConfirmationDialog } from '@/components/ConfirmationDialog';
import RequestTrialDialog from './RequestTrialDialog';
import type { FormValues } from './types';


interface RequestTrialStructureProps {
  isOpen: boolean,
  setIsOpen: (isOpen: boolean) => void,
  isMutationInProgress: boolean,
  activeStep: number,
  OpenButtonComponent: React.FC<{ onClick: () => void }>,
}

const RequestTrialStructure = ({
  isOpen,
  setIsOpen,
  isMutationInProgress,
  activeStep,
  OpenButtonComponent,
}: RequestTrialStructureProps) => {
  const { t } = useTranslation();
  const {
    resetForm,
    values,
    initialValues,
  } = useFormikContext<FormValues>();


  const shouldShowConfirmationDialog = JSON.stringify(values) !== JSON.stringify(initialValues);

  const { updateUnsavedChangesInfo } = useUnsavedChangesManager('requestTrial');
  useEffect(() => {
    updateUnsavedChangesInfo(shouldShowConfirmationDialog);
  }, [updateUnsavedChangesInfo, shouldShowConfirmationDialog]);

  const { handleClose, dialogProps } = useConfirmationDialog({
    onConfirm: () => {
      setIsOpen(false);
      resetForm();
    },
    shouldShowConfirmationDialog,
    disableBackdropClick: true,
  });


  return (
    <>
      <OpenButtonComponent
        onClick={() => setIsOpen(true)}
      />
      <ConfirmationDialog
        content={{
          mainText: t('translation:allUnsavedChangesWillBeLost'),
        }}
        {...dialogProps}
      />
      <RequestTrialDialog
        isOpen={isOpen}
        activeStep={activeStep}
        isMutationInProgress={isMutationInProgress}
        handleClose={handleClose}
      />
    </>
  );
};


export default RequestTrialStructure;

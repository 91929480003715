import React from 'react';
import {
  Box,
  Stack,
  Typography,
  Skeleton,
} from '@mui/material';
import { User as AuthUser } from '@auth0/auth0-react';
import { User } from '@/types/PerfectBot';


interface UserInfoProps {
  authData: AuthUser | undefined,
  userData: User | undefined,
}

const UserInfo = ({ authData, userData }: UserInfoProps) => (
  <Box sx={{ px: 5, pt: 3, pb: 0 }}>
    <Stack spacing={0.5}>
      <Stack direction="row" spacing={0.5} alignItems="center">
        <Typography component="span" variant="h3" sx={{ width: '100%' }}>
          {userData?.fullName
            ? userData?.fullName
            : (<Skeleton variant="text" />)}
        </Typography>
      </Stack>
      <Typography
        variant="h5"
        sx={(theme) => ({ fontWeight: 700, color: theme.palette.grey[900] })}
      >
        {userData
          ? userData?.organization?.fullName
          : (<Skeleton variant="text" />)}
      </Typography>
      <Typography
        variant="h5"
        sx={(theme) => ({ color: theme.palette.grey[500] })}
      >
        {authData?.email}
      </Typography>
    </Stack>
  </Box>
);


export default UserInfo;

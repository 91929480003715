import React, { useState } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import type { HTTPError } from 'ky';
import * as log from 'loglevel';
import { useTranslation } from 'next-i18next';
import { useMutation } from '@tanstack/react-query';
import { useSnackbar } from 'notistack';
import { requestTrial } from '@/api/instances/integrations';
import { useInstanceContext } from '@/contexts/InstanceContext';
import { customGtmEvent } from '@/utils/gtm';
import type { FormValues } from './types';
import RequestTrialStructure from './RequestTrialStructure';


const SignupSchema = Yup.object().shape({
  email: Yup.string().email('error:validation.invalidEmail').required('error:validation.fieldRequired'),
});


interface RequestTrialProps {
  OpenButtonComponent: React.FC<{ onClick: () => void }>,
}

const RequestTrial = ({ OpenButtonComponent }: RequestTrialProps) => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState(0);

  const { instanceId } = useInstanceContext();
  const mutation = useMutation<void, HTTPError, FormValues>((values) => requestTrial(
    instanceId,
    values,
  ));

  const handleSetIsOpen = (newValue: boolean) => {
    if (!isOpen) {
      setActiveStep(0);
    }
    setIsOpen(newValue);
  };

  return (
    <Formik<FormValues>
      initialValues={{
        email: '',
        website: '',
      }}
      validationSchema={SignupSchema}
      onSubmit={async (values, { resetForm }) => {
        try {
          await mutation.mutateAsync(values);
          customGtmEvent('request-trial-form-submission');
          setActiveStep(1);
          resetForm();
        } catch (e) {
          log.error(e);
          enqueueSnackbar(t('error:errorTryAgain'), { variant: 'error' });
        }
      }}
    >
      <RequestTrialStructure
        isOpen={isOpen}
        setIsOpen={handleSetIsOpen}
        activeStep={activeStep}
        isMutationInProgress={mutation.isLoading}
        OpenButtonComponent={OpenButtonComponent}
      />
    </Formik>
  );
};


export default RequestTrial;

import React from 'react';
import { useTranslation } from 'next-i18next';
import {
  styled,
  Box,
  Stack,
  Button,
  Paper,
  Typography,
  dialogClasses,
  buttonClasses,
} from '@mui/material';
import { livechatDemoVideoUrl, livechatTourUrl } from '@/constants';
import DialogWithXButton from '@/components/DialogWithXButton';
import IconPlayButton from '@/svg/icons/play-button.svg';
import IconMenuNewWindow from '@/svg/icons/menu_new_window.svg';
import LivechatLogo from '@/svg/images/partners/livechat_logo.svg';
// big icons
import IconChanels from '@/svg/images/chanels-icon.svg';
import IconMonitoring from '@/svg/images/monitoring-icon.svg';
import IconHandover from '@/svg/images/handover-icon.svg';
import IconAnalytics from '@/svg/images/analytics-icon.svg';
// logo
import LogoFreshworks from '@/svg/images/partners/freshworks-logo.svg';
import LogoGenesys from '@/svg/images/partners/genesys-logo.svg';
import LogoGorgias from '@/svg/images/partners/gorgias.svg';
import LogoSalesforce from '@/svg/images/partners/salesforce-logo.svg';
import LogoShopify from '@/svg/images/partners/shopify.svg';
import LogoZendesk from '@/svg/images/partners/zendesk-logo.svg';


const Dialog = styled(DialogWithXButton)`
  .${dialogClasses.paper} {
    width: 1120px;
    max-width: 1120px;
    padding-bottom: 0;
  }
`;

const BottomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;
  padding-bottom: 24px;
  width: 100%;
  box-shadow: 0 5px 10px #0029674D;
`;

const BlueLine = styled(Box)`
  position: absolute;
  width: 100%;
  height: 80px;
  background-color: ${({ theme }) => theme.palette.blue[50]};
`;

const TilePaper = styled(Paper)`
  position: relative;
  width: 240px;
  height: 210px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.blue[75]};
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

const OrangeButton = styled(Button)`
  width: 254px;

  &.${buttonClasses.contained} {
    background-color: ${({ theme }) => theme.palette.common.livechatOrange};

    &:hover {
      background-color: ${({ theme }) => (
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    theme.palette.grey[850]
  )};
    }
  }

  &.${buttonClasses.outlined} {
    border-color: ${({ theme }) => theme.palette.common.livechatOrange};
    color: ${({ theme }) => theme.palette.common.livechatOrange};

    &:hover {
      color: ${({ theme }) => theme.palette.common.white};
      background-color: ${({ theme }) => theme.palette.common.livechatOrange};
    }
  }
`;

interface TileProps {
  icon: JSX.Element,
  title: string,
  text: string,
}

const Tile = ({
  icon,
  title,
  text,
}: TileProps) => (
  <TilePaper>
    <Stack
      alignItems="center"
      spacing="10px"
      sx={{
        p: 3.5,
        textAlign: 'center',
      }}
    >
      {icon}
      <Typography
        variant="h2"
        component="div"
        sx={(theme) => ({
          fontWeight: 'bold',
          color: theme.palette.text.black,
        })}
      >
        {title}
      </Typography>
      <Typography
        variant="subtitle1"
        component="div"
        sx={{
          fontWeight: 'normal',
          lineHeight: 1.5,
        }}
      >
        {text}
      </Typography>
    </Stack>
  </TilePaper>
);


export interface LivechatInfoDialogProps {
  open: boolean,
  onClose: () => void,
}

const livechatLogoHeight = 54;
const LivechatInfoDialog = ({
  open,
  onClose,
}: LivechatInfoDialogProps) => {
  const { t } = useTranslation();


  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Stack alignItems="center">
        <Typography
          variant="h1"
          component="div"
          sx={{ fontWeight: 700 }}
        >
          {t('translation:livechatSandboxModal.useThePremiumChatSoftware')}
        </Typography>
        <Box sx={{ mt: 3.5 }}>
          <LivechatLogo height={livechatLogoHeight} width={livechatLogoHeight * 5} />
        </Box>
        <Box
          sx={{
            mt: 3.5,
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <BlueLine />
          <Stack
            direction="row"
            spacing="20px"
            sx={{ mt: '20px' }}
          >
            <Tile
              icon={<IconChanels width="48" height="48" />}
              title={t('translation:livechatSandboxModal.channels')}
              text={t('translation:livechatSandboxModal.supportYourCustomersOnWwwAndFbMessenger')}
            />
            <Tile
              icon={<IconMonitoring width="48" height="48" />}
              title={t('translation:livechatSandboxModal.monitoring')}
              text={t('translation:livechatSandboxModal.monitorPerfectBotConversationsInLiveChat')}
            />
            <Tile
              icon={<IconHandover width="48" height="48" />}
              title={t('translation:livechatSandboxModal.humanHandover')}
              text={t('translation:livechatSandboxModal.transferComplexProblemsToAgentsInLiveChat')}
            />
            <Tile
              icon={<IconAnalytics width="48" height="48" />}
              title={t('translation:livechatSandboxModal.analytics')}
              text={t('translation:livechatSandboxModal.monitorChatAnalyticsInLiveChat')}
            />
          </Stack>
        </Box>
        <Stack
          direction="row"
          spacing="20px"
          sx={{ mt: '30px' }}
        >
          <OrangeButton
            endIcon={<IconMenuNewWindow height={24} width={24} />}
            href={livechatTourUrl}
            // @ts-ignore
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('translation:livechatSandboxModal.exploreLivechat')}
          </OrangeButton>
          <OrangeButton
            variant="outlined"
            startIcon={<IconPlayButton />}
            endIcon={<IconMenuNewWindow height={24} width={24} />}
            href={livechatDemoVideoUrl}
            // @ts-ignore
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('translation:livechatSandboxModal.watchLiveChatDemo')}
          </OrangeButton>
        </Stack>
        <BottomBox>
          <Typography
            variant="subtitle1"
            sx={{ mt: '22px' }}
          >
            {t('translation:livechatSandboxModal.wantAnotherCustomerSupportSoftware')}
          </Typography>
          <Stack
            direction="row"
            spacing={5}
            sx={{ mt: 4 }}
          >
            <LogoZendesk width="119.958" height="26.184" />
            <LogoShopify width="118.388" height="33.725" />
            <LogoGorgias width="127.262" height="29.887" />
            <LogoGenesys width="132.418" height="26.819" />
            <LogoSalesforce width="64.136" height="44.892" />
            <LogoFreshworks width="144.284" height="28.929" />
          </Stack>
        </BottomBox>
      </Stack>
    </Dialog>
  );
};


export default LivechatInfoDialog;

import { useCallback } from 'react';


function useUnsavedChangesManager(target: UnsavedChangesManagerValues) {
  const doesUnsavedChangesExist = useCallback((): boolean => {
    if (!window.unsavedChangesManager) {
      return false;
    }

    return window.unsavedChangesManager[target] ?? false;
  }, [target]);

  const updateUnsavedChangesInfo = useCallback((value: boolean) => {
    if (window.unsavedChangesManager === undefined) {
      window.unsavedChangesManager = {};
    }
    window.unsavedChangesManager[target] = value;
  }, [target]);

  return { doesUnsavedChangesExist, updateUnsavedChangesInfo };
}

export default useUnsavedChangesManager;

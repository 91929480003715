import IconMenuAllCategories from '@/svg/icons/menu_all_categories.svg';
import IconMenuBusiness from '@/svg/icons/menu_business.svg';
import IconMenuClaims from '@/svg/icons/menu_claims.svg';
import IconMenuDeliveries from '@/svg/icons/menu_deliveries.svg';
import IconMenuOffers from '@/svg/icons/menu_offers.svg';
import IconMenuOrders from '@/svg/icons/menu_orders.svg';
import IconMenuPayments from '@/svg/icons/menu_payments.svg';
import IconMenuReturns from '@/svg/icons/menu_returns.svg';
import IconMenuDefaultIcon from '@/svg/icons/menu_default_icon.svg';
import IconMenuProductsFashion from '@/svg/icons/menu_products_fashion.svg';
import IconMenuProductsBeauty from '@/svg/icons/menu_products_beauty.svg';
import IconMenuProductsSport from '@/svg/icons/menu_products_sport.svg';
import IconMenuProductsBookstore from '@/svg/icons/menu_products_bookstore.svg';
import type { CustomIcon } from '@/types/PerfectBot';


export function getCategoryIcon(categoryId: string) {
  switch (categoryId) {
    case 'allCategories':
      return IconMenuAllCategories as CustomIcon;
    case 'business':
      return IconMenuBusiness as CustomIcon;
    case 'claims':
      return IconMenuClaims as CustomIcon;
    case 'deliveries':
      return IconMenuDeliveries as CustomIcon;
    case 'offers':
      return IconMenuOffers as CustomIcon;
    case 'orders':
      return IconMenuOrders as CustomIcon;
    case 'payments':
      return IconMenuPayments as CustomIcon;
    case 'returns':
      return IconMenuReturns as CustomIcon;
    case 'products-fashion':
      return IconMenuProductsFashion as CustomIcon;
    case 'products-beauty':
      return IconMenuProductsBeauty as CustomIcon;
    case 'products-sports':
      return IconMenuProductsSport as CustomIcon;
    case 'products-bookstores':
      return IconMenuProductsBookstore as CustomIcon;
    default:
      return IconMenuDefaultIcon as CustomIcon;
  }
}

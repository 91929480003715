import React from 'react';
import IconChat from '@/svg/icons/menu_chats.svg';
import IconArchives from '@/svg/icons/menu_archives.svg';
import GorgiasLogo from '@/svg/images/partners/gorgias_logo_dark.svg';
import { CustomIcon, Instance } from '@/types/PerfectBot';
import ChatIntegration from '@/components/AppLayout/Sidebar/ChatIntegration';


const menuItems: Array<{
  key: keyof Instance['externalLinks'],
  icon: CustomIcon,
  label: string,
}> = [
  {
    key: 'gorgias:chats',
    icon: IconChat,
    label: 'translation:menu.chats',
  },
  {
    key: 'gorgias:orders',
    icon: IconArchives,
    label: 'translation:menu.orders',
  },
];

const logoHeight = 21;

const GorgiasSection = () => (
  <ChatIntegration
    menuItems={menuItems}
    headerLogo={(
      <GorgiasLogo
        height={logoHeight}
        width={logoHeight * 4.5}
        style={{ marginLeft: '-1px' }}
      />
    )}
  />
);


export default GorgiasSection;

import React from 'react';
import MuiButton, { buttonClasses, ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import IconClose from '@/svg/icons/close_12.svg';
import IconPlayButton from '@/svg/icons/play-button.svg';
import IconSpinningLoader from '@/components/buttons/IconSpinningLoader';
import skipPropsPropagation from '@/utils/skipPropsPropagation';


const Button = styled(MuiButton, skipPropsPropagation('smallIcon'))<{ smallIcon?: boolean }>`
  min-width: 160px;
  padding-top: 6px;
  padding-bottom: 6px;
  transition: background-position 400ms ease-in-out;
  
  &:not(:disabled) {
    background: linear-gradient(90deg, rgba(224, 0, 134, 1) 0%, rgba(40, 0, 236, 1) 28%, rgba(51, 31, 154, 1) 72%, rgba(116, 0, 172, 1) 100%);
    background-size: 400% 100%;
    background-position: 100% 100%;
  }
  
  &.${buttonClasses.outlined} {
    background: transparent;
    border-color: ${({ theme }) => theme.palette.primary.main};
    
    .${buttonClasses.endIcon} > svg {
      ${({ smallIcon }) => smallIcon && ('font-size: 0.85em;')}
    }
  }

  :hover {
    background-position: 0 100%;
  }
  
  .${buttonClasses.endIcon} > svg {
    ${({ smallIcon }) => smallIcon && ('font-size: 1em;')}
  }
`;


export interface ButtonWithGradientProps extends ButtonProps {
  status?: 'loading' | 'inProgress' | 'ready',
}

const ButtonWithGradient = ({
  children,
  status = 'ready',
  onClick,
  disabled,
  ...otherProps
}: ButtonWithGradientProps) => {
  let icon;
  let content = children;
  let variant: 'outlined' | 'contained' = 'contained';
  let smallIcon = true;

  if (!disabled) {
    if (status === 'loading') {
      content = '';
      smallIcon = false;
      icon = <IconSpinningLoader />;
    } else if (status === 'inProgress') {
      icon = <IconClose />;
      variant = 'outlined';
    } else {
      icon = <IconPlayButton />;
    }
  }

  return (
    <Button
      color="primary"
      variant={variant}
      endIcon={icon}
      onClick={status === 'loading' ? () => {} : onClick}
      smallIcon={smallIcon}
      disabled={disabled}
      {...otherProps}
    >
      {content}
    </Button>
  );
};


export default ButtonWithGradient;

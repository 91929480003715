import React from 'react';
import { styled, SxProps, Typography } from '@mui/material';
import skipPropsPropagation from '@/utils/skipPropsPropagation';


const Text = styled(
  Typography,
  skipPropsPropagation('noMargin', 'disabled'),
)<{ noMargin?: boolean, disabled?: boolean }>(({ theme, noMargin, disabled }) => ({
  fontWeight: 700,
  marginLeft: !noMargin ? '10px' : undefined,
  color: disabled ? theme.palette.grey[500] : undefined,
}));


export interface LabelProps {
  children: string | undefined,
  noMargin?: boolean,
  component?: string,
  disabled?: boolean,
  sx?: SxProps,
}

const Label = ({
  children,
  component = 'div',
  noMargin,
  disabled,
  sx,
}: LabelProps) => (
  <Text
    // @ts-ignore
    component={component}
    variant="h4"
    noMargin={noMargin}
    disabled={disabled}
    sx={sx}
  >
    {children}
  </Text>
);


export default Label;

import React from 'react';
import List from '@mui/material/List';
import SectionHeader from './SectionHeader';


export interface MenuListProps {
  isListOpen: boolean,
  toggleOpen: () => void,
  children: React.ReactNode,
  headerTitle: React.ReactNode,
}

const MenuList = ({
  isListOpen,
  toggleOpen,
  children,
  headerTitle,
}: MenuListProps) => (
  <List
    component="div"
    sx={{ mb: 'var(--theme-spacing-1half)', p: 0 }}
    subheader={(
      <SectionHeader
        open={isListOpen}
        onClick={toggleOpen}
      >
        {headerTitle}
      </SectionHeader>
    )}
  >
    {children}
  </List>
);


export default MenuList;
